<template>
  <div>
    <div>
      <img src="../static/14911634701061_.pic_hd.jpg" style="width: 100%; height: 100%;">
    </div>
    <div class="box_1" v-if="LG">
      <div class="hd_11">
        登录
      </div>
      <input type="" name="" id="" placeholder="账号" v-model="userName" class="sr_1" />
      <input type="password" name="" id="" placeholder="密码" v-model="userPwd" class="sr_1" />
      <div class="sr_1" style="background-color: rgba(92, 199, 192, 1); text-align: center; color: #ffffff;"
        @click="DL">
        确认登录
      </div>
      <div class="sr_1" style="position: relative; font-size: 1rem;text-align: center;">
        <!-- <span class="xz_1" :class="xz1 ? 'c_b':'c_n' " @click="chag">
        </span> -->
        <el-checkbox v-model="checked"></el-checkbox>
        <span style="margin-left: 1rem; color: rgba(255, 255, 255, 1); cursor: pointer;">记住密码</span>
        <!-- <span @click="LG = false" style="margin-left: 1rem; color: red;cursor: pointer;">忘记密码</span> -->
      </div>
    </div>

    <div class="box_1" v-if="!LG">
      <div style="color: #FFFFFF; font-size: 3rem; padding: 1rem 0;">
        找回密码
      </div>
      <el-form style="width: 29rem;" :label-position="labelPosition" label-width="4rem" :model="formLabelAlign">
        <el-form-item label="手机号" style="position: relative;">
          <el-input v-model="formLabelAlign.phone"></el-input>
          <el-button @click="Yzm" size="mini" :disabled="An"
            style="position: absolute; top: 50%; transform: translateY(-50%); right: 1rem;" type="primary" plain>
            {{code}}
          </el-button>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input v-model="formLabelAlign.code"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="formLabelAlign.pswd"></el-input>
        </el-form-item>
      </el-form>
      <div v-if="LG" class="sr_1" style="background-color: rgba(92, 199, 192, 1); text-align: center; color: #ffffff;"
        @click="DL">
        确认登录
      </div>
      <div v-if="!LG" class="sr_1"
        style="margin: 0; background-color: rgba(92, 199, 192, 1); text-align: center; color: #ffffff;" @click="DL1">
        确认修改
      </div>
      <div class="sr_1" style="margin: 0; position: relative; font-size: 1rem;text-align: center;">
        <span @click="LG = true" style="margin-left: 1rem; color: red;cursor: pointer;">账号登录</span>
      </div>
    </div>
  </div>
</template>

<script>
  // import HelloWorld from './components/HelloWorld.vue'
  import axios from "axios"
  export default {
    name: 'app',
    components: {
      // HelloWorld

    },
    data() {
      return {
        // userName: 'admin',
        // userPwd: '123456aq',
        userName: '',
        userPwd: '',
        xz1: false,
        checked: false,
        LG: true,
        labelPosition: 'right',
        formLabelAlign: {
          phone: '',
          code: '',
          pswd: ''
        },
        An: false,
        code: '获取验证码',
        timer: null
      }
    },
    created() {
      this.getCookie()
    },
    methods: {
      Yzm() {
        let t1 = 10
        let obj = {
          "phone": this.formLabelAlign.phone
        }
        this.$ajax('code', 'post', obj).then(res => {
          console.log(res);
          if (res.code == 2000) {
            this.An = true
            this.timer = setInterval(() => {
              if (t1 > 0) {
                this.code = t1 + "s后重新获取"
                t1--;
                console.log(t1);
              } else {
                this.code = '重新获取'
                this.An = false;
                clearInterval(this.timer);
                this.timer = null;
                t1 = 10
              }
            }, 1000)
          } else {
            this.$message(res.message);
          }
        })

      },
      chag() {
        this.xz1 = !this.xz1
      },
      DL() {
        let data = {
          "account": this.userName,
          "password": this.userPwd
        }

        this.$ajax('login', 'post', data).then(res => {
          if (res.code == 2000) {
            sessionStorage.setItem("token", res.data.token);
            // this.$newGet('/index/info').then(res => {
            //   console.log(res, 'lg');
            if (this.checked) {
              // 传入账号名，密码，和保存天数3个参数
              this.setCookie(this.userName, this.userPwd, 7)
            } else {
              // 如果没有选中自动登录，那就清除cookie
              this.setCookie('', '', -1) // 修改2值都为空，天数为负1天就好了
            }
            // sessionStorage.setItem("Lg", res.data);
            this.$router.push('/about')
            // })
            // return



            // console.log(res);
            // if (res.code == 2000) {
            // if (this.checked) {
            //   // 传入账号名，密码，和保存天数3个参数
            //   this.setCookie(this.userName, this.userPwd, 7)
            // } else {
            //   // 如果没有选中自动登录，那就清除cookie
            //   this.setCookie('', '', -1) // 修改2值都为空，天数为负1天就好了
            // }
            // sessionStorage.setItem("Lg", res.data);
            // this.$router.push('/about')
            // }


          } else {
            this.$message('密码错误！');
          }
        })
      },
      DL1(){
        let obj = {
          	"code": this.formLabelAlign.code,
          	"password": this.formLabelAlign.pswd,
          	"phone": this.formLabelAlign.phone
        }
        this.$ajax('wjmm', 'post', obj).then(res => {
          console.log(res);
          if (res.code == 2000) {
            this.LG = true
            this.$message({
              type: 'success',
              message: res.message
            });
          } else {
            this.$message(res.message);
          }
        })
      },

      // 设置cookie
      setCookie(name, pwd, exdays) {
        var exdate = new Date() // 获取时间
        exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) // 保存的天数
        // 字符串拼接cookie
        window.document.cookie = 'userName' + '=' + name + ';path=/;expires=' + exdate.toGMTString()
        window.document.cookie = 'userPwd' + '=' + pwd + ';path=/;expires=' + exdate.toGMTString()
      },
      // 读取cookie 将用户名和密码回显到input框中
      getCookie() {
        if (document.cookie.length > 0) {
          var arr = document.cookie.split('; ') // 这里显示的格式需要切割一下自己可输出看下
          this.checked = true
          for (var i = 0; i < arr.length; i++) {
            var arr2 = arr[i].split('=') // 再次切割
            // 判断查找相对应的值
            if (arr2[0] === 'userName') {
              this.userName = arr2[1] // 保存到保存数据的地方
            } else if (arr2[0] === 'userPwd') {
              this.userPwd = arr2[1]
            }
          }
        }
      }
    }
  }
</script>

<style>
  /* #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */


  .box_1 {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33rem;
    height: 24rem;
    left: 70rem;
    top: 30rem;
    background: rgba(8, 21, 51, 0.6);
    /* box-shadow: 0px 0px 1.5rem rgba(6, 242, 242, 0.24), inset 0px 0px 2.6875rem rgba(255, 255, 255, 0.25); */
    /* background: rgba(8, 21, 51, 0.6); */
    box-shadow: 0px 0px 24.7561px rgba(6, 242, 242, 0.24), inset 0px 0px 43.3232px rgba(255, 255, 255, 0.25);
  }

  .hd_11 {
    font-size: 2.4rem;
    width: 100%;
    height: 3.3rem;
    line-height: 3.3rem;
    text-align: center;
    margin-top: 2.3rem;
    color: rgba(28, 184, 184, 1);
  }

  .sr_1 {
    width: 23rem;
    height:3rem;
    line-height: 3rem;
    font-size: 1.2rem;
    padding-left: 1rem;
    margin-top: 1.5rem;
    background-color: #FFFFFF;
  }

  .sr_1:last-child {
    background-color: unset;
  }

  .xz_1 {
    position: absolute;
    top: 1.8rem;
    left: 40%;
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: .2rem;
    /* margin-right: 0.625rem; */
  }

  .c_n {
    background-color: unset;
  }

  .c_b {
    background-color: aqua;
  }
</style>
